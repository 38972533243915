import React, { useState } from 'react';
import './SellerLayout.css'; // Optional: CSS file for styling the Admin layout
import AdminSidebar from "../../components/admin/AdminSidebar/AdminSidebar";
import AdminHeader from '../../components/admin/AdminHeader/AdminHeader'; 
import ArrowIcon from '../../components/icons/ArrowIcon/ArrowIcon';
import HomeIcon from '../../components/icons/HomeIcon/HomeIcon';
import OptionsIcon from '../../components/icons/OptionsIcon/OptionsIcon';
import { useLocation, Link } from 'react-router-dom';

const SellerLayout = ({ children }) => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  // Sidebar visibility state
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className="admin-layout">
      {/* Sidebar toggles visibility on mobile */}
      <div className={`admin-content ${isSidebarVisible ? 'visible' : ''}`}>
        <AdminSidebar />
      </div>
      <div className="admin-main">
        <div className="admin-top">
          <AdminHeader />
          <div className='admin-options-top' onClick={toggleSidebar}>
            <OptionsIcon color='black' size='24' className="sidebar-toggle-btn"/>
          </div>
        </div>
        <main className="admin-children">
          <div className="href-history">
            {pathSegments.length > 1 && pathSegments.map((segment, index) => {
              const path = `/${pathSegments.slice(0, index + 1).join('/')}`;
              const isLast = index === pathSegments.length - 1;
              return (
                <span key={index}>
                  {
                    index === 0 &&
                    <>
                      <Link className="padding-top-4-px link-history" to={'/'}>
                        <HomeIcon color="#009AD6"/>
                      </Link>
                      <ArrowIcon color="#009AD6" />
                    </>
                  }
                  {!isLast ? (
                    <>
                      <Link className="text-align-center link-history padding-bottom-4-px" to={path}>{segment}</Link>
                      <ArrowIcon color="#009AD6" />
                    </>
                  ) : (
                    <span className="padding-bottom-4-px">{segment}</span>
                  )}
                </span>
              );
            })}
          </div>
          {children}
        </main>
      </div>
    </div>
  );
};

export default SellerLayout;
