import './ModelPage.css';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import modelApi from '../../../services/apiService/modelApi';
import modelImageApi from '../../../services/apiService/modelImageApi';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';
import AddIcon from '../../../components/icons/AddIcon/AddIcon';
import EditIcon from '../../../components/icons/EditIcon/EditIcon';
import ModelRegisterModal from '../../../components/modals/ModelRegisterModal/ModelRegisterModal';
import configService from '../../../services/configService';

const ModelPage = () => {
    const dispatch = useDispatch();
    const { code } = useParams();
    const [model, setModel] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [isModelRegisterOpen, setIsModelRegisterOpen] = useState(false);

    const openRegisterModel = () => {
        setIsModelRegisterOpen(true);
    }

    const closeRegisterModel = () => {
        setIsModelRegisterOpen(false);
    }

    useEffect(() => {
        const fetchModel = async () => {
        try {
            dispatch(setLoading(true));
            const response = await modelApi.getModelByCode(code, 'ModelImage');
            setModel(response);
          } catch (error) {
            toast.error('Erro ao carregar a marca!');
          } finally {
            dispatch(setLoading(false));
        }
        };

        fetchModel();
    }, [code, refresh, dispatch]);

    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        files.forEach((file) => {
            // Check file size
            if (file.size > configService.getDefaultMaxSizeFileUpload()) {
                toast.error(`Arquivo ${file.name} é muito grande. Tamanho máximo é de ${configService.getDefaultMaxSizeFileUpload()} MB.`);
                return;
            }

            const reader = new FileReader();
            reader.onload = async () => {
                try {
                    dispatch(setLoading(true));

                    const base64String = reader.result;
                    await modelImageApi.createModel({
                        ParentCode: model.Code,
                        Base64: base64String,
                    });

                    toast.success('Criado com sucesso!');
                    setRefresh((prev) => !prev);
                } catch (error) {
                    toast.error('Error: ' + error.message);
                } finally {
                    dispatch(setLoading(false));
                }
            };
            reader.readAsDataURL(file);
        });

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleAddImageClick = () => {
        if(model.ModelImage && model.ModelImage.length === 5)
            toast.error('Marca não pode conter mais que 5 imagens!');
        else
            fileInputRef.current.click(); 
    };

    const handleImageDelete = async (code) => {
        try {
          dispatch(setLoading(true));
          var json = {
            code: code
          }
          await modelImageApi.deleteModel(json);
          toast.success('Excluído com sucesso!');
          setRefresh((prev) => !prev);
        } catch (error) {
          toast.error('Houve erro ao excluir: ' + error.message);
        } finally {
          dispatch(setLoading(false));
        }
    };

    const updateBranch = async (model) => {
        try {
            dispatch(setLoading(true));
            await modelApi.updateModel(model);
            toast.success('Atualizado com sucesso!');
            setRefresh((prev) => !prev);
          } catch (error) {
            toast.error('Houve erro ao editar: ' + error.message);
          } finally {
            dispatch(setLoading(false));
          }
    }

    if (!model) {
        return <p>Loading...</p>;
    }

    return (
    <div className="container-admin-page">
        <ModelRegisterModal isOpen={isModelRegisterOpen} onClose={closeRegisterModel} onSubmit={updateBranch} codeModel={model.Code} />
        <h1>{model.Name}</h1>
        <div className="model-info div-with-border">
            <div className='model-images-options'>
                <h2>Informações Básicas:</h2>
                <div className='clickable' onClick={openRegisterModel}>
                    <EditIcon onClick/>
                </div>
            </div>
            <p><strong>Nome:</strong></p>
            <p>{model.Name}</p>
            <p><strong>Descrição:</strong></p>
            <p>
            {model.Description?.split('\n').map((line, index) => (
                <p key={index}>{line}</p>
            ))}
            </p>
        </div>

        <div className="model-info div-with-border margin-top-default">
            {model.ModelImage && model.ModelImage.length > 0 ? (
            <div className="model-images">
                <div className='model-images-options'>
                    <h3>Imagens ({model.ModelImage.length}):</h3>
                    <div className='clickable' onClick={handleAddImageClick}>
                        <AddIcon/>
                    </div>
                </div>
                <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
                
                <div className='model-images-list'>
                    {model.ModelImage.map((image, index) => (
                        <div className="image-item"key={image.Code}>
                            <div className='image-preview'>
                                <img src={image.Url} alt={`${model.Name}`} />
                                <button type="button" onClick={() => handleImageDelete(image.Code)}>
                                    Delete
                                </button>
                            </div>
                            <span>Imagem {index + 1} - {model.Name}</span>
                        </div>
                    ))}
                </div>
            </div>
            )
            :
            (
                <div className="model-images">
                <div className='model-images-options'>
                    <h3>Imagens ({model.ModelImage.length}):</h3>
                    <div className='clickable' onClick={handleAddImageClick}>
                        <AddIcon/>
                    </div>
                </div>
                <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
            </div>
            )}
        </div>
    </div>
  );
};

export default ModelPage;
