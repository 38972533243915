import React, { useState, useEffect } from 'react';
import './GroupListPage.css';
import groupApi from '../../../services/apiService/groupApi';
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch } from 'react-redux';
import configService from '../../../services/configService';
import Pagination from '../../../components/common/Pagination/Pagination'; 
import { toast } from 'react-toastify';
import { putDateOnPattern } from '../../../utils/functions';
import FilterComponent from '../../../components/admin/FilterComponent/FilterComponent';
import { saveAs } from 'file-saver';
import EditIcon from '../../../components/icons/EditIcon/EditIcon';
import RegisterGrupoModal from '../../../components/modals/RegisterGrupoModal/RegisterGrupoModal';
import ConfirmModal from '../../../components/modals/ConfirmModal/ConfirmModal';

const GroupListPage = () => {
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [totalClients, setTotalClients] = useState(0);
    const [isGroupRegisterOpen, setIsGroupRegisterOpen] = useState(false);
    const [isGroupEditOpen, setIsGroupEditOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const quantity = configService.getDefaultNumberOfItemsTable(); 
    const orderBy = "Created:Desc";

    const openRegisterGroup = () => {
        setIsGroupRegisterOpen(true);
    }

    const closeRegisterGroup = () => {
        setIsGroupRegisterOpen(false);
    }

    const closeEditGroup = () => {
        setIsGroupEditOpen(false);
    }

    useEffect(() => {
        const fetchLogs = async () => {
            dispatch(setLoading(true));
            try {
                const response = await groupApi.getGroupsPaginated({ page, quantity, orderBy, term: searchTerm });
                setItems(response.Results);
                setTotalPages(response.TotalPages);
                setTotalClients(response.TotalCount);
            } catch (error) {
                toast.error('Erro ao buscar os itens.');
            } finally {
                dispatch(setLoading(false));
            }
        };
        fetchLogs();
    }, [page, quantity, searchTerm, refresh, dispatch]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const search = ({term} = {}) => {
        setSearchTerm(term);
    }

    const openEditGroup = (group) => {
        setSelectedGroup(group);
        setIsGroupEditOpen(true);
    };

    const exportFunction = async (term) => {
        try {
            dispatch(setLoading(true));
            const response = await groupApi.exportGroups({ term: term });
            
            if (response.Status === 200 && response.Object) {
                saveAs(response.Object, 'reportGroups.csv');
                toast.success('Relatório gerado com sucesso!');
            } else {
                toast.error('Erro ao gerar o relatório 1');
            }
        } catch (error) {
            toast.error('Erro ao gerar o relatório');
        }
        finally{
            dispatch(setLoading(false));
        }
    };

    const createGroup = async (group) =>{
        try{
            dispatch(setLoading(true));
            var response = await groupApi.createGroup(group);

            toast.success(response.Description + ' criado com sucesso!');
            setRefresh((prev) => !prev);
        }
        catch(error){
            toast.error('Error :' + error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    const updateGroup = async (group) =>{
        try{
            dispatch(setLoading(true));
            var response = await groupApi.updateGroup(selectedGroup.Code, group);

            toast.success(response.Description + ' atualizado com sucesso!');
            setRefresh((prev) => !prev);
        }
        catch(error){
            toast.error('Error :' + error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    const handleYesModalDelete = async (item) => {
        dispatch(setLoading(true));
        try {
          if(item.IsActive) {
            await groupApi.setInactive(item.Code);
          } else {
            await groupApi.setActive(item.Code);
          }
          toast.success(item.Value + ' atualizado com sucesso!');
          // Trigger the refresh to re-fetch data
          setRefresh(prev => !prev);
        } catch (error) {
          toast.error('Error updating item status:' + error);
        } finally {
          dispatch(setLoading(false));
          setIsModalDeleteOpen(false);
        }
      };
    
      const handleNoModalDelete = () =>{
        setIsModalDeleteOpen(false);
      };

    const deleteOption = (group) => {
        setSelectedGroup(group);
        setIsModalDeleteOpen(true);
    }

    return (
    <div className="container-admin-page">
        <RegisterGrupoModal
            isOpen={isGroupRegisterOpen || isGroupEditOpen}
            onClose={isGroupRegisterOpen ? closeRegisterGroup : closeEditGroup}
            onSubmit={isGroupRegisterOpen ? createGroup : updateGroup}
            oldDescription={isGroupEditOpen && selectedGroup ? selectedGroup.Description : ''}
            oldId={isGroupEditOpen && selectedGroup ? selectedGroup.GroupId : ''}
            oldType={isGroupEditOpen && selectedGroup ? selectedGroup.Type : ''}
            showId={true}

        />
        <ConfirmModal
          isOpen={isModalDeleteOpen}
          title="Atenção"
          message={`Deseja realmente ${selectedGroup?.IsActive ? 'desativar' : 'ativar'} o grupo ${selectedGroup?.Description}?`}
          onYes={handleYesModalDelete}
          onNo={handleNoModalDelete}
          yesLabel="Confirm"
          noLabel="Cancel"
          confirmData={selectedGroup}
        />
        <h1>Lista dos grupos</h1>
        <div className='container-admin-page-filters div-with-border'>
            <h3>Filtros</h3>
            <FilterComponent placeHolder={'Nome'} showTermFilter={true} submitFilter={search} exportFunction={exportFunction}/>
        </div>
        <div className='container-admin-page-table div-with-border'>
            <div className='right-component'>
                <button className='main-button' onClick={openRegisterGroup}>Novo Grupo</button>
            </div>
            <table className="admin-table">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Código Interno</th>
                        <th>Descrição</th>
                        <th>Tipo</th>
                        <th>Criado</th>
                        <th>Atualizado</th>
                        <th>Status</th>
                        <th></th>
                        </tr>
                    </thead>
                <tbody>
                {items.map((item) => (
                    <tr key={item.Code}>
                        <td><span>{item.Code}</span></td>
                        <td><span>{item.GroupId}</span></td>
                        <td><span>{item.Description}</span></td>
                        <td><span>{item.Type}</span></td>
                        <td><span>{putDateOnPattern(item.Created)}</span></td>
                        <td><span>{putDateOnPattern(item.Updated)}</span></td>
                        <td className='clickable'><span className={item.IsActive ? 'item-active brighten-on-hover' : 'item-inactive brighten-on-hover'} onClick={() => deleteOption(item)}>{item.IsActive ? 'Ativo' : 'Inativo'}</span></td>
                        <td><span className='option-link' onClick={() => openEditGroup(item)}><EditIcon/></span></td>
                    </tr>
                ))}
                </tbody>
            </table>
            <sub>Total de Grupos: {totalClients}</sub>
            <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
    </div>
    );
};

export default GroupListPage;
