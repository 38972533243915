import React, { useEffect, useState, useCallback } from 'react';
import './Profile.css';
import personApi from '../../../services/apiService/personApi'; 
import { useSelector } from 'react-redux';
import { selectUserCode } from '../../../services/redux/authSlice';
import { toast } from 'react-toastify';
import PencilIcon from '../../../components/icons/PencilIcon/PencilIcon';
import { maskCPF, maskCEP, maskCNPJ, maskPhone } from '../../../components/masks/masks';
import UpdateInfoModal from '../../../components/modals/UpdateInfoModal/UpdateInfoModal';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';
import addressApi from '../../../services/apiService/addressApi';
import SectionWithImage from '../../../components/client/SectionWithImage/SectionWithImage';

const Profile = ({code = null, children=null}) => {
  const [profile, setProfile] = useState({});
  const [address, setAddress] = useState({});
  const userCode = useSelector(selectUserCode);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoType, setInfoType] = useState('personal');
  const dispatch = useDispatch();

  const handleOpenModal = (type) => {
    setInfoType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchProfile = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const response = await personApi.getPersonByCode(code ? code : userCode, 'Address,PersonType,Address.City');
      setProfile(response);
      setAddress(response.Address[0]);
    } catch (error) {
      toast.error('Erro ao buscar os dados de perfil.');
    } finally {
      dispatch(setLoading(false));
    }
  }, [code, userCode, dispatch]); // Dependencies

  // Call fetchProfile inside useEffect for the initial data loading
  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const handleSubmit = async (updatedData) => {
    
    try {
      dispatch(setLoading(true));

      await personApi.updatePerson(updatedData);
      dispatch(setLoading(false));
      toast.success('Alterado com sucesso!');
      fetchProfile();

    } catch (error) {
      dispatch(setLoading(false));
      toast.error('Erro ao atualizar o perfil.');
    }
    
    handleCloseModal();
  };

  const handleSubmitAddress = async (updatedData) => {
    try {
      dispatch(setLoading(true));

      await addressApi.updateAddress(updatedData);
      toast.success('Alterado com sucesso!');
      dispatch(setLoading(false));
      
      fetchProfile();
    } catch (error) {
      dispatch(setLoading(false));
      toast.error('Erro ao atualizar o endereço.');
    }
    
    handleCloseModal();
  };

  if(!profile)
    return(<div className='profile-container'>Carregando</div>)
  else
  return (
    <div className={code ? "profile-container" : "profile-container"}>
      {
        infoType === 'personal' ?
        <UpdateInfoModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            infoType={infoType}
            formData={profile}
            onSubmit={handleSubmit}
        />
        :
        <UpdateInfoModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            infoType={infoType}
            formData={address}
            onSubmit={handleSubmitAddress}
        />
      }
      {
        children &&
        <SectionWithImage
            backgroundImageUrl={'https://storagealbus.blob.core.windows.net/padrao/secondary.jpg'}
            title={'Perfil'}
        />
      }
      <div className='bigger-space'>

          <div className="box space-double-bottom">
          {
              code ?
              <div>
                  <h2>Informações Básicas</h2>
              </div>
              :
              <div className='box-title'>
                  <h2>Informações Básicas</h2>
                  <div className='clickable' onClick={() => handleOpenModal('personal')}>
                      <PencilIcon size={24} color='black'/>
                  </div>
              </div>
          }
              <div className="info-group">
                  <p><strong>Nome:</strong> {profile?.Name}</p>
                  <p><strong>Email:</strong> {profile?.Email}</p>
                  {
                      profile?.IsPhysical === 1 ?
                      <p><strong>CPF:</strong> {maskCPF(profile?.PhysicalDocument)}</p>
                      :
                      <p><strong>CNPJ:</strong> {maskCNPJ(profile?.LegalDocument)}</p>
                  }
                  <p><strong>Telefone:</strong> {maskPhone(profile?.Phone1)}</p>
                  <p><strong>Celular:</strong> {maskPhone(profile?.Mobile)}</p>
                  <p><strong>Tipo de Usuário:</strong> {profile?.PersonType?.Name}</p>
              </div>
          </div>

          <div className="box">
          {
              code ?
              <div>
                  <h2>Endereço</h2>
              </div>
              :
              <div className='box-title'>
                  <h2>Endereço</h2>
                  <div className='clickable' onClick={() => handleOpenModal('address')}>
                      <PencilIcon size={24} color='black'/>
                  </div>
              </div>
          }
          <div className="info-group">
            <p><strong>Endereço:</strong> {address?.LongAddress}, {address?.Number}</p>
            <p><strong>Bairro:</strong> {address?.Neighborhood}</p>
            <p><strong>Complemento:</strong> {address?.Complement || 'Não informado'}</p>
            <p><strong>Cidade:</strong> {address?.City?.Name} - {address?.City?.StateCode}</p>
            <p><strong>CEP:</strong> {maskCEP(address?.Zipcode)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
