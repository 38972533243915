import React, { useState, useEffect } from 'react';
import './ShoppingCartListPage.css';
import shoppingCartApi from '../../../services/apiService/shoppingCartApi';
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch } from 'react-redux';
import configService from '../../../services/configService';
import Pagination from '../../../components/common/Pagination/Pagination'; 
import { toast } from 'react-toastify';
import { putDateOnPattern } from '../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import OpenIcon from '../../../components/icons/OpenIcon/OpenIcon';
import FilterComponent from '../../../components/admin/FilterComponent/FilterComponent';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';

const ShoppingCartListPage = () => {
    const dispatch = useDispatch();
    const isAdmin = useSelector((state) => state.auth.isAdmin);
    const code = useSelector((state) => state.auth.code);
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [seller, setSeller] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const quantity = configService.getDefaultNumberOfItemsTable(); 
    const orderBy = "Created:Desc";

    useEffect(() => {
        const fetchLogs = async () => {
            dispatch(setLoading(true));
            try {
                const response = await shoppingCartApi.getShoppingCartsPaginated({ page, quantity, orderBy, term: searchTerm, seller: isAdmin ? seller : code, include: 'Person,ShoppingCartItem.Product' });
                setItems(response.Results);
                setTotalPages(response.TotalPages);
                setTotalItems(response.TotalCount);
            } catch (error) {
                toast.error('Erro ao buscar os itens.');
            } finally {
                dispatch(setLoading(false));
            }
        };
        fetchLogs();
    }, [page, quantity, searchTerm, seller, isAdmin, code, dispatch]);

    const openClient = (code) => {
        navigate(`${code}`);
    }

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const search = ({term, seller} = {}) => {
        setSearchTerm(term);
        setSeller(seller);
    }

    const exportFunction = async (term) => {
        try {
            dispatch(setLoading(true));
            const response = await shoppingCartApi.exportShoppingCarts({ term: term });
            
            if (response.Status === 200 && response.Object) {
                saveAs(response.Object, 'reportShoppingCart.csv');
                toast.success('Relatório gerado com sucesso!');
            } else {
                toast.error('Erro ao gerar o relatório 1');
            }
        } catch (error) {
            toast.error('Erro ao gerar o relatório');
        }
        finally{
            dispatch(setLoading(false));
        }
    };

    return (
    <div className="container-admin-page">
        <h1>Orçamentos</h1>
        <div className='container-admin-page-filters div-with-border'>
            <h3>Filtros</h3>
            <FilterComponent placeHolder={'Cliente'} showTermFilter={true} showEndDate={false} showStartDate={false} showSellers={isAdmin} submitFilter={search} exportFunction={exportFunction}/>
        </div>
        <div className='container-admin-page-table div-with-border'>
            {/* {
                !isAdmin &&
                <div className='right-component'>
                    <button className='main-button' onClick={() => openClient('novo')}>Novo Orçamento</button>
                </div>
            } */}
            <table className="admin-table">
                <thead>
                    <tr>
                        {isAdmin && <th>Vendedor</th>}
                        <th>Cliente</th>
                        <th>Quantidade de itens</th>
                        <th>Criação</th>
                        <th>Atualização</th>
                        <th>Status</th>
                        <th></th>
                        </tr>
                    </thead>
                <tbody>
                {items.map((item) => (
                    <tr key={item.Code}>
                        {isAdmin && <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.Seller?.Name}</span></td>}
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.Person.Name}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.ShoppingCartItem.length}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{putDateOnPattern(item.Created)}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{putDateOnPattern(item.Updated)}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.Status === 'OPEN' ? 'Aberto' : (item.Status === 'IN PROGRESS' ? 'Em atendimento' : 'Concluído')}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}><OpenIcon/></span></td>
                    </tr>
                ))}
                </tbody>
            </table>
            <sub>Total de Orçamentos: {totalItems}</sub>
            <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
    </div>
    );
};

export default ShoppingCartListPage;
