import './AdminSidebar.css'; // Import the corresponding CSS file
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../services/redux/authSlice';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import logo from '../../../assets/images/logo_white_text_transparent.png';
import DashBoardIcon from '../../icons/DashBoardIcon/DashBoardIcon';
import ShoppingBagIcon from '../../icons/ShoppingBagIcon/ShoppingBagIcon';
import ShoppingCartIcon from '../../icons/ShoppingCartIcon/ShoppingCartIcon';
import UsersIcon from '../../icons/UsersIcon/UsersIcon';
import UserAddIcon from '../../icons/UserAddIcon/UserAddIcon';
import AdminUserIcon from '../../icons/AdminUserIcon/AdminUserIcon';
import SettingsIcon from '../../icons/SettingsIcon/SettingsIcon';
import LogoffIcon from '../../icons/LogoffIcon/LogoffIcon';
import BrandIcon from '../../icons/BrandIcon/BrandIcon';
import ModelIcon from '../../icons/ModelIcon/ModelIcon';
import { useLocation } from 'react-router-dom';
import BuildingIcon from '../../icons/BuildingIcon/BuildingIcon';
import GroupIcon from '../../icons/GroupIcon/GroupIcon';
import CategoryIcon from '../../icons/CategoryIcon/CategoryIcon';

const AdminSidebar = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  
  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
    toast.success("Até breve!");
  };

  return (
    <aside className="sidebar">
      <div className="sidebar__logo">
        <img src={logo} alt="Logo" />
      </div>
      <nav className="sidebar__menu">
        {isAdmin && <a href="/admin/dashboard" className={pathSegments.length === 0 || (pathSegments.length === 1 && pathSegments[0] === 'admin') || pathSegments[1] === 'dashboard' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><DashBoardIcon/>Dashboard</a>}
        {isAdmin && <a href="/admin/modelos" className={pathSegments[1] === 'modelos' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><ModelIcon color='white'/>Modelos</a>}
        {isAdmin && <a href="/admin/marcas" className={pathSegments[1] === 'marcas' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><BrandIcon/>Marcas</a>}
        {isAdmin && <a href="/admin/empresas" className={pathSegments[1] === 'empresas' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><BuildingIcon color='white'/>Empresas</a>}
        {isAdmin && <a href="/admin/grupos" className={pathSegments[1] === 'grupos' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><GroupIcon color='white' size={32} />Grupos</a>}
        {isAdmin && <a href="/admin/categorias" className={pathSegments[1] === 'categorias' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><CategoryIcon color='white'/>Categorias</a>}
        {isAdmin && <a href="/admin/produtos" className={pathSegments[1] === 'produtos' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><ShoppingBagIcon/>Produtos</a>}
        <a href={isAdmin ? "/admin/orcamentos" : "/orcamentos"} className={pathSegments[1] === 'orcamentos' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><ShoppingCartIcon/>Orçamentos</a>
        {isAdmin && <a href="/admin/clientes" className={pathSegments[1] === 'clientes' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><UsersIcon/>Clientes</a>}
        {isAdmin && <a href="/admin/vendedores" className={pathSegments[1] === 'vendedores' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><UserAddIcon/>Vendedores</a>}
        {isAdmin && <a href="/admin/administradores" className={pathSegments[1] === 'administradores' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><AdminUserIcon/>Administradores</a>}
        {isAdmin && <a href="/admin/configuracoes" className={pathSegments[1] === 'configuracoes' ? "sidebar__menu-item sidebar__menu-item-selected" : "sidebar__menu-item"}><SettingsIcon/>Configurações</a>}
      </nav>
      <button className="sidebar__logoff" onClick={handleLogout}>
        Sair <LogoffIcon/>
      </button>
    </aside>
  );
};

export default AdminSidebar;
