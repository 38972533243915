import React, { useState, useEffect } from 'react';
import './ModelClientListPage.css';
import productApi from '../../../services/apiService/productApi';
import brandApi from '../../../services/apiService/brandApi';
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch } from 'react-redux';
import Pagination from '../../../components/common/Pagination/Pagination'; 
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '../../../components/icons/SearchIcon/SearchIcon';
import SectionWithImage from '../../../components/client/SectionWithImage/SectionWithImage';
import HrefHistory from '../../../components/client/HrefHistory/HrefHistory';
import groupApi from '../../../services/apiService/groupApi';

const ModelClientListPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [brands, setBrands] = useState([]);
    const [groups, setGroups] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [filter, setFilter] = useState(false);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const quantity = 15; 
    const orderBy = "Created:Desc";
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const typeParam = params.get('type');
        const brandParam = params.get('brand');
        const sizesParam = params.get('size');
        const modelsParam = params.get('model');

        setSelectedTypes(typeParam ? typeParam.split(';').map(s => s.replace('%20', ' ')) : []);
        setSelectedBrands(brandParam ? brandParam.split(';').map(s => s.replace('%20', ' ')) : []);
        setSelectedSizes(sizesParam ? sizesParam.split(';').map(s => s.replace('%20', ' ')) : []);
        setSelectedModels(modelsParam ? modelsParam.split(';').map(s => s.replace('%20', ' ')) : []);
        setFilter(true);
    }, [location.search]);
    
    useEffect(() => {
        const fetchFiltersAndProducts = async () => {
            dispatch(setLoading(true));
            try {
                const brandsResponse = await brandApi.getBrands();
                setBrands(brandsResponse);

                const groupsResponse = await groupApi.getAllTypeGroups();
                setGroups(groupsResponse);

                const sizesResponse = await productApi.getAllSizes({
                    types: selectedTypes.join(';'),
                });
                setSizes(sizesResponse);

                const response = await productApi.getProductsPaginated({
                    page,
                    quantity,
                    orderBy,
                    term: searchTerm,
                    isActive: 1,
                    include: 'Company,Group,Brand,Model,Model.ModelImage,ProductImage',
                    brands: selectedBrands.join(';'),
                    groupTypes: selectedTypes.join(';'),
                    sizes: selectedSizes.join(';'),
                    model: selectedModels.join(';'),
                });
                setItems(response.Results);
                setTotalPages(response.TotalPages);
            } catch (error) {
                toast.error('Erro ao buscar os dados.');
            } finally {
                dispatch(setLoading(false));
            }
        };

        if(filter)
            fetchFiltersAndProducts();
    }, [page, selectedBrands, selectedTypes, selectedSizes, selectedModels, searchTerm, dispatch, filter]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleBrandClick = (brandCode) => {
        setSelectedModels([]);
        setSelectedBrands((prevSelected) =>
            prevSelected.includes(brandCode)
                ? prevSelected.filter((code) => code !== brandCode) 
                : [...prevSelected, brandCode]
        );
    };

    const handleTypeClick = (type) => {
        setSelectedModels([]);
        setSelectedTypes((prevSelected) =>
            prevSelected.includes(type)
                ? prevSelected.filter((code) => code !== type) 
                : [...prevSelected, type]
        );
    };

    const handleSizeClick = (size) => {
        setSelectedModels([]);
        setSelectedSizes((prevSelected) =>
            prevSelected.includes(size)
                ? prevSelected.filter((code) => code !== size) 
                : [...prevSelected, size]
        );
    };

    const openModel = (code) => {
        navigate(`${code}`);
    };

    return (
        <div>
            <SectionWithImage backgroundImageUrl={'https://storagealbus.blob.core.windows.net/padrao/secondary.jpg'} title={'Produtos'} />
            <HrefHistory/>
            <section className="model-list-section">
                <div className="model-filters">
                    <div className="filter-section-title">
                        <h2>Filtros</h2>
                    </div>
                    <div className='model-filters-input'>
                        <input
                            type="text"
                            placeholder="Pesquisar"
                            className="main-input"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <div className='form-group-input-icon'>
                            <SearchIcon size={20} color="black" />
                        </div>
                    </div>
                    <div className="filter-section">
                        <h3>Veículos</h3>
                        <div className="filter-options">
                            {groups && groups.length > 0 && (
                                groups.map((group) => (
                                    <div key={group} className="filter-option">
                                        <input 
                                            type="checkbox" 
                                            id={group} 
                                            checked={selectedTypes.includes(group)}
                                            onChange={() => handleTypeClick(group)}
                                        />
                                        <label htmlFor={group}>{group}</label>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className="filter-section">
                        <h3>Marcas</h3>
                        <div className="filter-options">
                            {brands && brands.length > 0 && (
                                brands.map((brand) => (
                                    <div key={brand.Code} className="filter-option">
                                        <input 
                                            type="checkbox" 
                                            id={brand.Code} 
                                            checked={selectedBrands.includes(brand.Code)}
                                            onChange={() => handleBrandClick(brand.Code)}
                                        />
                                        <label htmlFor={brand.Code}>{brand.Description}</label>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className="filter-section">
                        <h3>Medidas</h3>
                        <div className="filter-options">
                            {sizes && sizes.length > 0 && (
                                sizes.map((size) => (
                                    <div key={size} className="filter-option">
                                        <input 
                                            type="checkbox" 
                                            id={size} 
                                            checked={selectedSizes.includes(size)}
                                            onChange={() => handleSizeClick(size)}
                                        />
                                        <label htmlFor={size}>{size}</label>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <div className="model-items">
                    <div className="model-items-grid">
                        {items.map((item) => (
                            <div key={item.Code} className="model-item-card" onClick={() => openModel(item.Code)}>
                                {
                                    item.ProductImage && item.ProductImage.length > 0 ?
                                    <img 
                                        src={item.ProductImage && item.ProductImage.length > 0 ? item.ProductImage[0].Url : 'https://storagealbus.blob.core.windows.net/padrao/nocontent.png'} 
                                        alt={item.Description} 
                                        className="model-item-image" 
                                    />
                                    :
                                    <img 
                                        src={item.Model && item.Model.ModelImage && item.Model.ModelImage.length > 0 ? item.Model.ModelImage[0].Url : 'https://storagealbus.blob.core.windows.net/padrao/nocontent.png'} 
                                        alt={item.Description} 
                                        className="model-item-image" 
                                    />

                                }
                                <div className="model-item-details">
                                    <h5>{item.Size}</h5>
                                    <h6>{item.Brand.Description}</h6>
                                    <p>
                                        Modelo: {item.Model.Name}<br/>
                                        Veículos: {item.Group.Type}<br/>
                                        <br/>
                                        Descrição: {item.Model.Description.length > 200 ? 
                                        `${item.Model.Description.substring(0, 200)}...` 
                                        : 
                                        item.Model.Description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
                </div>
            </section>
        </div>
    );
};

export default ModelClientListPage;
