import api from './serviceApi'; // Import the axios instance

const productApi = {
  /**
   * Fetch a product by its code.
   * @param {string} code - The code of the product.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The product data.
   */
  getProductByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/product/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching product by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a paginated list of products with optional filters.
   * @param {Object} params - Query parameters such as page, quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of products.
   */
  getProductsPaginated: async (params) => {
    try {
      const response = await api.get('/product/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching products paginated:', error);
      throw error;
    }
  },

  /**
   * Fetch a list of products with optional filters.
   * @param {Object} params - Query parameters such as page, quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of products.
   */
  getProductsByDescription: async (params) => {
    try {
      const response = await api.get('/product/by-description', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching products by description:', error);
      throw error;
    }
  },

  /**
   * Export a list of products based on filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportProducts: async (params) => {
    try {
      const response = await api.get('/product/export', { params });
      return response.data;
    } catch (error) {
      console.error('Error exporting products:', error);
      throw error;
    }
  },

  /**
   * Create a new product.
   * @param {Object} productData - The product data to create.
   * @returns {Promise<Object>} - The created product data.
   */
  createProduct: async (productData) => {
    try {
      const response = await api.post('/product', productData);
      return response.data;
    } catch (error) {
      console.error('Error creating product:', error);
      throw error;
    }
  },

  /**
   * Update an existing product.
   * @param {Object} productData - The product data to update.
   * @returns {Promise<Object>} - The updated product data.
   */
  updateProduct: async (productData) => {
    try {
      const response = await api.put('/product', productData);
      return response.data;
    } catch (error) {
      console.error('Error updating product:', error);
      throw error;
    }
  },

  /**
   * Delete a product by its code.
   * @param {Object} productData - The product data to delete.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  deleteProduct: async (productData) => {
    try {
      const response = await api.delete('/product', { data: productData });
      return response.data;
    } catch (error) {
      console.error('Error deleting product:', error);
      throw error;
    }
  },

  /**
   * inativate a product by their code.
   * @param {string} productCode - The product code to inativate.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  setInactive: async (productCode) => {
    try {
      const response = await api.put('/product/setInactive/' + productCode);
      return response.data;
    } catch (error) {
      console.error('Error inativating product:', error);
      throw error;
    }
  },

  /**
   * active a product by their code.
   * @param {string} productCode - The product code to active.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  setActive: async (productCode) => {
    try {
      const response = await api.put('/product/setActive/' + productCode);
      return response.data;
    } catch (error) {
      console.error('Error inativating product:', error);
      throw error;
    }
  },

  /**
   * Fetch all products with optional filters.
   * @param {Object} params - Optional query parameters.
   * @returns {Promise<Object>} - The list of products.
   */
  getAllProducts: async (params = {}) => {
    try {
      const response = await api.get('/product', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error;
    }
  },
  
  /**
   * Fetch all groups.
   * @param {Object} [params] - Optional query parameters.
   * @returns {Promise<Object>} - The list of groups.
   */
  getAllSizes: async (params = {}) => {
    try {
      const response = await api.get('/product/sizes', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching sizes:', error);
      throw error;
    }
  },

  /**
   * Import products from a CSV file.
   * @param {FormData} formData - The form data containing the CSV file.
   * @returns {Promise<Object>} - The response data from the import.
   */
  importCsv: async (formData) => {
    try {
      const response = await api.post('/product/importCsv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error importing CSV:', error);
      throw error;
    }
  }
};

export default productApi;
