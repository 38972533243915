import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SellerLayout from '../layouts/seller/SellerLayout';
import ShoppingCartListPage from '../pages/admin/ShoppingCartListPage/ShoppingCartListPage';
import ShoppingCartDetailPage from '../pages/admin/ShoppingCartDetailPage/ShoppingCartDetailPage';
import ShoppingCartCreatePage from '../pages/admin/ShoppingCartCreatePage/ShoppingCartCreatePage';
import ShoopingCartLastStage from '../pages/admin/ShoopingCartLastStage/ShoopingCartLastStage';

const SellerRoutes = () => (
  <SellerLayout>
    <Routes>
    <Route path="/" element={<Navigate to="/orcamentos" replace />} />
      <Route path='/orcamentos' element={<ShoppingCartListPage />}/>
      <Route path='/orcamentos/:code' element={<ShoppingCartDetailPage />}/>
      <Route path='/orcamentos/novo' element={<ShoppingCartCreatePage />}/>
      <Route path='/orcamentos/novo/fechamento' element={<ShoopingCartLastStage />}/>
      </Routes>
  </SellerLayout>
);

export default SellerRoutes;
