import './SelectSellerModal.css';
import React, { useState } from 'react';

const SelectSellerModal = ({ isOpen, sellers, onSelect, onClose, confirmData }) => {
    const [selectedSeller, setSelectedSeller] = useState(null);
    if (!isOpen) return null;

    const handleSelectChange = (e) => {
        const selectedSeller = sellers.find(
            (seller) => seller.Code === e.target.value
        );

        setSelectedSeller(selectedSeller);
    };

    const onConfirm = () => {
        onSelect(confirmData, selectedSeller);
    }

    return (
        <div className="modal-backdrop">
            <div className="modal-container-generic">
                <h2 className="modal-title">Selecione um Vendedor</h2>
                <select
                    className="main-select"
                    onChange={handleSelectChange}
                    defaultValue=""
                >
                    <option value="" disabled>
                        Selecione um vendedor
                    </option>
                    {sellers.map((seller) => (
                        <option key={seller.Code} value={seller.Code}>
                            {seller.Name} - {seller.Email}
                        </option>
                    ))}
                </select>
                <div className="modal-actions">
                    <button className="modal-no modal-button" onClick={onClose}>
                        Fechar
                    </button>
                    <button className="modal-yes modal-button" onClick={onConfirm}>
                        Selecionar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SelectSellerModal;
