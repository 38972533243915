import { createSlice } from '@reduxjs/toolkit';
import storageService from '../storageService';
import Config from '../../configuration/storageConfiguration';

// Retrieve cart items from storage or initialize as an empty array
const storedItems = JSON.parse(storageService.getItem(Config.CART_ITEMS)) || [];

const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState: {
    items: storedItems, // Start with items from storage
  },
  reducers: {
    addItem: (state, action) => {
      const existingItem = state.items.find(item => item.Code === action.payload.Code);

      if (!existingItem) {
        state.items.push(action.payload); // Add new item if it doesn't exist
      }
      else {
        existingItem.Quantity = action.payload.Quantity;
      }

      storageService.setItem(Config.CART_ITEMS, JSON.stringify(state.items)); // Save updated cart to storage
    },
    removeItem: (state, action) => {
      state.items = state.items.filter(item => item.Code !== action.payload); // Remove item by code
      storageService.setItem(Config.CART_ITEMS, JSON.stringify(state.items)); // Update storage
    },
    clearCart: (state) => {
      state.items = []; // Clear all items from cart
      storageService.removeItem(Config.CART_ITEMS); // Clear storage
    },
  },
});

// Actions
export const { addItem, removeItem, clearCart } = shoppingCartSlice.actions;

// Selectors
export const selectAllCartItems = (state) => state.shoppingCart.items;
export const selectItemExistsInCart = (code) => (state) => 
  state.shoppingCart.items.some(item => item.code === code);

export default shoppingCartSlice.reducer;
