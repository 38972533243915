import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import productApi from '../../../services/apiService/productApi';
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch, useSelector } from 'react-redux';
import './ModelClientItemPage.css';
import { toast } from 'react-toastify';
import SectionWithImage from '../../../components/client/SectionWithImage/SectionWithImage';
import { addItem, removeItem, selectAllCartItems } from '../../../services/redux/shoppingCartSlice';
import HrefHistory from '../../../components/client/HrefHistory/HrefHistory';

const ModelClientItemPage = () => {
    const { guid } = useParams();
    const dispatch = useDispatch();
    const [model, setModel] = useState(null);
    const [product, setProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [refresh, setRefresh] = useState(false);
    const [mensagemVisivel, setMensagemVisivel] = useState(false);

    const cartItems = useSelector(selectAllCartItems) || [];
    const isItemInCart = (itemCode) => {
        if(cartItems !== undefined)
            return cartItems.find(cartItem => cartItem.Code === itemCode);
        return false;
    }

    useEffect(() => {
        const fetchModel = async () => {
            dispatch(setLoading(true));
            try {
                const response = await productApi.getProductByCode(guid, 'Company,Group,Brand,Model,Model.ModelImage,ProductImage');
                setModel(response.Model);
                setProduct(response);
            } catch (error) {
                toast.error('Erro ao buscar o produto.');
            } finally {
                dispatch(setLoading(false));
            }
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
          };
        window.addEventListener('resize', handleResize);

        fetchModel();
    }, [guid, refresh, dispatch]);

    const handleAddToCart = async (item) => {

        dispatch(setLoading(true));
        try {
            const updatedItem = { ...item, Quantity: quantity };
            dispatch(addItem(updatedItem));
            setMensagemVisivel(true);

            // Esconder a mensagem após 3 segundos
            setTimeout(() => {
            setMensagemVisivel(false);
            }, 5000);

        } catch (error) {
            toast.error('Erro ao buscar o produto.');
        } finally {
            dispatch(setLoading(false));
        }
        setRefresh((prev) => !prev);
    };

    const handleRemoveToCart = (item) => {
        dispatch(removeItem(item));
        setRefresh((prev) => !prev);
    };

    const handleQuantityChange = (val) => {
        if(val + quantity > 0)
            setQuantity(prev => prev += val);
    }

    if (!model) return <p>Loading</p>;

    return (
        <div className='model-description'>
            <SectionWithImage
                backgroundImageUrl={'https://storagealbus.blob.core.windows.net/padrao/secondary.jpg'}
                title={'Produto'}
            />
            <HrefHistory/>
            <div className="model-description-container">
                <div className="model-details-image">
                    {
                        product.ProductImage && product.ProductImage.length > 0 ?
                        <img 
                        src={product.ProductImage && product.ProductImage.length > 0 ? product.ProductImage[0].Url : 'https://storagealbus.blob.core.windows.net/padrao/nocontent.png'} 
                            alt={model.Name} 
                        />
                        :
                        <img 
                            src={model.ModelImage && model.ModelImage.length > 0 ? model.ModelImage[0].Url : 'https://storagealbus.blob.core.windows.net/padrao/nocontent.png'} 
                            alt={model.Name} 
                        />

                    }
                    {
                        !isMobile &&
                        <div className="model-measures-container">
                            <div className='model-measures-container-title'>
                                <h1>Medida</h1>
                            </div>
                            <div key={product.Code} className="measure-item">
                                <h3>{product.Size}</h3>
                                <div className="item-quantity">
                                    <button className='clickable button-options-itens brighten-on-hover' onClick={() => handleQuantityChange(-1)}>-</button>
                                    <input type="text" value={quantity} readOnly />
                                    <button className='clickable button-options-itens brighten-on-hover' onClick={() => handleQuantityChange(1)}>+</button>
                                </div>
                            </div>
                            {
                                isItemInCart(product.Code) ?
                                <div className='model-details-button right-component'>
                                    <button className="measure-add-button-remove clickable item-inactive brighten-on-hover" onClick={() => handleRemoveToCart(product.Code)}>- Remover</button>
                                    {mensagemVisivel && (
                                        <p style={{ color: "green" }}>Produto adicionado ao carrinho!</p>
                                    )}
                                </div>
                                :
                                <div className='model-details-button right-component'>
                                    <button className="clickable measure-add-button brighten-on-hover" onClick={() => handleAddToCart(product)}>+ Adicionar</button>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className="model-details">
                    <div className="model-details">
                        <h1>{product.Size} - Modelo {model.Name}</h1>
                        <h2>Descrição</h2>
                        <p>{model.Description}</p>
                    </div>
                    <div className="model-details">
                    <h2>Detalhes</h2>
                    {
                        product?.Observation ? 
                        <table className='client-table'>
                            <tbody>
                                {
                                    product?.Observation?.split('\n').map((line, index) => (
                                    <tr key={index}>
                                        <td>{line.split(':')[0]}</td>
                                        <td>{line.split(':')[1]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        :
                        <h3>Sem detalhes</h3>
                    }
                    </div>
                </div>
            </div>
            {
                isMobile &&
                <div className="model-measures-container">
                    <div className='model-measures-container-title'>
                        <h1>Medida</h1>
                    </div>
                    <div key={product.Code} className="measure-item">
                        <h3>{product.Size}</h3>
                        <div className="item-quantity">
                            <button className='clickable button-options-itens brighten-on-hover' onClick={() => handleQuantityChange(-1)}>-</button>
                            <input type="text" value={quantity} readOnly />
                            <button className='clickable button-options-itens brighten-on-hover' onClick={() => handleQuantityChange(1)}>+</button>
                        </div>
                    </div>
                    {
                        isItemInCart(product.Code) ?
                        <div className='model-details-button right-component'>
                            <button className="measure-add-button-remove clickable item-inactive brighten-on-hover" onClick={() => handleRemoveToCart(product.Code)}>- Remover</button>
                            {mensagemVisivel && (
                                <p style={{ color: "green" }}>Produto adicionado ao carrinho!</p>
                            )}
                        </div>
                        :
                        <div className='model-details-button right-component'>
                            <button className="clickable measure-add-button brighten-on-hover" onClick={() => handleAddToCart(product)}>+ Adicionar</button>
                        </div>
                    }                    
                </div>
            }
        </div>
    );
};

export default ModelClientItemPage;
