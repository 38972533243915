import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
import homeApi from '../../../services/apiService/homeApi';
import logo from '../../../assets/images/logo_white_text_transparent.png';
import CartIcon from '../../icons/CartIcon/CartIcon';
import LoginIcon from '../../icons/LoginIcon/LoginIcon';
import SearchIcon from '../../icons/SearchIcon/SearchIcon';
import UserCustomIcon from '../../icons/UserCustomIcon/UserCustomIcon';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserName, logout } from '../../../services/redux/authSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../../modals/LoginModal/LoginModal';
import UserRegistrationModal from '../../modals/UserRegistrationModal/UserRegistrationModal';
import ChangePasswordModal from '../../modals/ChangePasswordModal/ChangePasswordModal';
import RequestRecoveryPasswordModal from '../../modals/RequestRecoveryPasswordModal/RequestRecoveryPasswordModal';
import productApi from '../../../services/apiService/productApi';
import { setLoading } from '../../../services/redux/loadingSlice';
import { removeItem, selectAllCartItems } from '../../../services/redux/shoppingCartSlice';
import { openCloseLogin } from '../../../services/redux/loginSlice';

const Navbar = () => {
  const dispatch = useDispatch();
  const userName = useSelector(selectUserName);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [navItems, setNavItems] = useState([]);
  const isLoginOpenRedux = useSelector((state) => state.login.isOpen);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isRegisterOpen, setRegisterOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownCartOpen, setDropdownCartOpen] = useState(false);
  const [isModalChangePassOpen, setIsModalChangePassOpen] = useState(false);
  const [isModalRequestRecoveryPass, setIsModalRequestRecoveryPass] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const cartItems = useSelector(selectAllCartItems) || [];
  const menuRefCart = useRef(null);
  const menuRefUser = useRef(null);

  // Event handlers for modal and dropdown actions
  const openModal = () => {
    setIsModalChangePassOpen(true);
    setDropdownOpen(false);
  };
  const closeModal = () => setIsModalChangePassOpen(false);
  const OpenRegister = () => {
    setLoginOpen(false);
    dispatch(openCloseLogin(false));
    setRegisterOpen(true);
  };
  const OpenLogin = () => {
    setRegisterOpen(false);
    setLoginOpen(true);
  };
  const OpenModalRecoveryPassword = () => {
    setLoginOpen(false);
    dispatch(openCloseLogin(false));
    setIsModalRequestRecoveryPass(true);
  };
  const CoseModalRecoveryPassword = () => setIsModalRequestRecoveryPass(false);
  const handleUserIconClick = () => setDropdownOpen(!isDropdownOpen);
  const handleCartIconClick = () => setDropdownCartOpen(!isDropdownOpen);
  const handleLogout = () => {
    dispatch(logout());
    toast.success('Volte sempre!');
    setDropdownOpen(false);
    navigate('/');
  };

  const closeSubmenu = (event) => {
    if (menuRefCart.current && !menuRefCart.current.contains(event.target)) {
      setDropdownCartOpen(false);
    }
    if (menuRefUser.current && !menuRefUser.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
      // Add event listener when the component mounts
      document.addEventListener('click', closeSubmenu);

      // Clean up the event listener when the component unmounts
      return () => {
          document.removeEventListener('click', closeSubmenu);
      };
  }, []);

  useEffect(() => {
    const fetchNavItems = async () => {
      dispatch(setLoading(true));
      try {
        const data = await homeApi.getNavbar();
        setNavItems(data.NavBar);
      } catch (error) {
        console.error('Failed to fetch navbar items:', error);
      }
      finally{
        dispatch(setLoading(false));
      }
    };
    fetchNavItems();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const renderNavItems = (items) => (
    items.map((item, index) => (
      <li key={index} className={item.IsTitle ? "navbar__link" : "navbar__link shadow-on-hover"}>
        {item.Href ? (
          <a className="brighten-on-hover" href={item.Href}>{item.Item}</a>
        ) : 
            item.IsTitle ? 
            <span className="brighten-on-hover clickable"><b>{item.Item}</b></span>
            :
            <span className="brighten-on-hover clickable">{item.Item}</span>
        }
        {item.SubItems && (
          <ul className="navbar__submenu">
            {renderNavItems(item.SubItems)}
          </ul>
        )}
      </li>
    ))
  );

  const handleRemoveToCart = (item) => {
    dispatch(removeItem(item));
  };

  const handleSearchChange = async (event) => {
    var term = event.target.value;
    setSearchTerm(term);

    if (term.length >= 3) { // Start searching after 3 characters
      try {
        dispatch(setLoading(true));
        const results = await productApi.getProductsByDescription({ term: searchTerm, include: "Model.ModelImage,ProductImage" });
        setSearchResults(results);
      } catch (error) {
        toast.error('Error fetching products by description:' + error);
      }
      finally{
        dispatch(setLoading(false));
      }
    } else {
      setSearchResults([]); // Clear results if search term is too short
    }
  };

  const closeCart = () => {
    window.location.href = '/carrinho';
  }

  return (
    <header className="navbar">
      {/* Modals */}
      <LoginModal isOpen={isLoginOpen} onClose={() => setLoginOpen(false)} onRegisterClick={OpenRegister} onRecoveryPassClick={OpenModalRecoveryPassword} />
      <LoginModal isOpen={isLoginOpenRedux} onClose={() => dispatch(openCloseLogin(false))} onRegisterClick={OpenRegister} onRecoveryPassClick={OpenModalRecoveryPassword} />
      <UserRegistrationModal isOpen={isRegisterOpen} onClose={() => setRegisterOpen(false)} />
      <ChangePasswordModal isOpen={isModalChangePassOpen} onClose={closeModal} />
      <RequestRecoveryPasswordModal isOpen={isModalRequestRecoveryPass} onClose={CoseModalRecoveryPassword} />
      {/* Navbar Content */}
      {isMobile ? (
        <div className="navbar__mobile">
          <div className="navbar__top__info">
            <div className="navbar__logo clickable" onClick={() => navigate('/')}>
              <img src={logo} alt="Logo"/>
            </div>
            <button className="navbar__hamburger" onClick={toggleMenu}>
              ☰
            </button>
          </div>
          {isMenuOpen && (
            <nav className="navbar__mobile__menu">
              <ul className="ul-navbar-itens">{renderNavItems(navItems)}</ul>
              <div 
                  className={`navbar__menu-item ${isDropdownCartOpen ? 'active' : ''}`}
                  ref={menuRefCart}
                  onClick={handleCartIconClick}>
                  <span>Carrinho 
                  {
                    cartItems.length > 1 ?
                    <span> ( {cartItems.length} itens)</span>
                    :
                    cartItems.length > 0 &&
                    <span> ( {cartItems.length} item)</span>
                  }
                  </span>
                  {
                    isDropdownCartOpen &&
                    (
                      cartItems.length > 0 ?
                      <div className="navbar__dropdown">
                        <div className='navbar__dropdown-cart-itens'>
                          <h3><strong>Itens</strong></h3>
                        {
                          cartItems.map((item) => (
                            <div key={item.Code} className='item-intern-cart'>
                              <h4>{item.Description}</h4>
                              <button className='clickable item-inactive brighten-on-hover' onClick={() => handleRemoveToCart(item.Code)}>X</button>
                            </div>
                          ))
                        }
                          <button className='clickable item-active close-cart-navbar brighten-on-hover' onClick={closeCart}>Fechar Carrinho</button>
                        </div>
                      </div>
                      :
                      <div className="navbar__dropdown">
                        <p>Sem itens</p>
                      </div>
                    )
                  }
              </div>
              {isAuthenticated ? (
                <div
                  className={`navbar__menu-item ${isDropdownOpen ? 'active' : ''}`}
                  onClick={handleUserIconClick}
                >
                  <span>Olá, {userName}</span>
                  <UserCustomIcon size={32} color="white" />
                  {isDropdownOpen && (
                    <div className="navbar__dropdown">
                      <a href="/orcamentos" className="navbar__dropdown-item brighten-on-hover">Meus orçamentos</a>
                      <a href="/perfil" className="navbar__dropdown-item brighten-on-hover">Perfil</a>
                      <div onClick={openModal} className="navbar__dropdown-item brighten-on-hover">Alterar Senha</div>
                      <div onClick={handleLogout} className="navbar__dropdown-item clickable brighten-on-hover">Sair</div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="navbar__menu-item" onClick={OpenLogin}>
                  <span>Entre ou cadastre-se</span>
                  <LoginIcon size={24} color="white" />
                </div>
              )}
              <div className="navbar__combobox">
                <input
                  type="text"
                  placeholder="Pesquisar"
                  className="main-input"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className='form-group-input-icon'>
                  <SearchIcon size={20} color="black" />
                </div>
                <div className={`navbar__select-container ${searchResults.length > 0 || searchTerm ? 'show' : ''}`}>
                  <div className="custom-dropdown">
                    {searchResults.length > 0 ? (
                      <ul className="custom-dropdown-list">
                        {searchResults.map((product) => (
                          <li key={product.Code} className="custom-dropdown-item">
                            <a href={'/modelos/' + product.Code}>
                              {
                              product.Model.ModelImage && product.Model.ModelImage.length > 0 ? 
                                <img
                                  src={product.Model.ModelImage[0].Url}
                                  alt={product.Model.Name}
                                  className="product-image"
                                />
                              :
                              product.ProductImage && product.ProductImage.length > 0 &&
                                <img
                                  src={product.ProductImage[0].Url}
                                  alt={product.Description}
                                  className="product-image"
                                />
                              }
                              <span>{product.Description}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="custom-dropdown-item">Não encontrado</div>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          )}
        </div>
      ) : (
        <>
          <div className="navbar__options">
            <div className="navbar__top__info">
              <div className="navbar__logo clickable" onClick={() => navigate('/')} >
                <img src={logo} alt="Logo"/>
              </div>
              <div className="navbar__menu">
                <div 
                  className={`navbar__menu-item ${isDropdownCartOpen ? 'active' : ''}`}
                  ref={menuRefCart}
                  onClick={handleCartIconClick}>
                  <span>Carrinho</span>
                  <CartIcon size={40} color="white" />
                  {
                    cartItems.length > 0 &&
                    <div className='quantity-itens-cart'>{cartItems.length}</div>
                  }
                  {
                    isDropdownCartOpen &&
                    (
                      cartItems.length > 0 ?
                      <div className="navbar__dropdown">
                        <div className='navbar__dropdown-cart-itens'>
                          <h3><strong>Itens</strong></h3>
                        {
                          cartItems.map((item) => (
                            <div key={item.Code} className='item-intern-cart'>
                              <h4>{item.Description}</h4>
                              <button className='clickable item-inactive brighten-on-hover' onClick={() => handleRemoveToCart(item.Code)}>X</button>
                            </div>
                          ))
                        }
                          <button className='clickable item-active close-cart-navbar brighten-on-hover' onClick={closeCart}>Fechar Carrinho</button>
                        </div>
                      </div>
                      :
                      <div className="navbar__dropdown">
                        <p>Sem itens</p>
                      </div>
                    )
                  }
                </div>
                {isAuthenticated ? (
                  <div
                  className={`navbar__menu-item ${isDropdownOpen ? 'active' : ''}`}
                  ref={menuRefUser}
                  onClick={handleUserIconClick}
                >
                  <span>Olá, {userName}</span>
                  <UserCustomIcon size={32} color="white" />
                  {isDropdownOpen && (
                    <div className="navbar__dropdown">
                      <a href="/orcamentos" className="navbar__dropdown-item brighten-on-hover">Meus orçamentos</a>
                      <a href="/perfil" className="navbar__dropdown-item brighten-on-hover">Perfil</a>
                      <div onClick={openModal} className="navbar__dropdown-item brighten-on-hover">Alterar Senha</div>
                      <div onClick={handleLogout} className="navbar__dropdown-item clickable brighten-on-hover">Sair</div>
                    </div>
                  )}
                </div>
                ) : (
                  <div className="navbar__menu-item" onClick={OpenLogin}>
                    <span>Entre ou <br/>cadastre-se</span>
                    <LoginIcon size={40} color="white" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="navbar__options__bottom">
              <nav className="navbar__links">
                <ul className="ul-navbar-itens">{renderNavItems(navItems)}</ul>
              </nav>
              <div className="navbar__combobox">
                <input
                  type="text"
                  placeholder="Pesquisar"
                  className="main-input"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className='form-group-input-icon'>
                  <SearchIcon size={20} color="black" />
                </div>
                <div className={`navbar__select-container ${searchResults.length > 0 || searchTerm ? 'show' : ''}`}>
                  <div className="custom-dropdown">
                    {searchResults.length > 0 ? (
                      <ul className="custom-dropdown-list">
                        {searchResults.map((product) => (
                          <li key={product.Code} className="custom-dropdown-item">
                            <a className='option-link product-link' href={'/modelos/' + product.Code}>
                              {
                              product.Model.ModelImage && product.Model.ModelImage.length > 0 ? 
                                <img
                                  src={product.Model.ModelImage[0].Url}
                                  alt={product.Model.Name}
                                  className="product-image"
                                />
                              :
                              product.ProductImage && product.ProductImage.length > 0 &&
                                <img
                                  src={product.ProductImage[0].Url}
                                  alt={product.Description}
                                  className="product-image"
                                />
                              }
                              <span>{product.Description}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="custom-dropdown-item">Não encontrado</div>
                    )}
                  </div>
                </div>
              </div>
          </div>
        </>
      )}
    </header>
  );
};

export default Navbar;
